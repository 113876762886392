@import '~bootstrap/scss/bootstrap.scss';
@import '../velocity/scss/theme-1.scss';

// BEGIN: Velocity customizations
.fullpage-loading-indicator {
  color: $color;
}

.header {
  h1.logo {
    //font-family: "Roboto Slab", arial, serif;

    a {
      // Allow our longer title to sit on one line
      max-width: none;
    }
  }
}

.testimonials {
  // Ensure the testimonial image is the correct size
  // regardless of source image size
  figure.profile img {
    height: 140px;
    width: 140px;
  }
}

$bg-height: 780px !default;
$header-height: 80px;

.bg-slider-wrapper {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: $bg-height;
  background: darken($text-color, 5%);

  .carousel-item {
    height: $bg-height;

    // Darken the images to ensure they don't clash with the
    // white text overlay
    filter: brightness(50%);
  }
}

.headline-bg {
  &.pricing-headline-bg {
    // Mimic the look of the homepage slider
    height: $bg-height;
    filter: brightness(50%);

    // Remove the theme's default background image
    background-image: none;
  }
}

.login-page {
  .form-group {
    &.password {
      // Decrease the space between the password input and the remember me checkbox
      margin-bottom: 0.5rem;
    }
  }

  .forgot-password {
    // Align the bottoms of the remember me checkbox and forgot password link
    margin-top: 8px;
  }
}

// Match the authentication form style on all forms
.form-group {
  position: relative;

  // Style the FontAwesome icons beside every input
  .svg-inline--fa {
    position: absolute;
    left: 10px;
    top: 13px;
    color: $grey;
    font-size: 13px;
  }

  .form-row {
    .svg-inline--fa {
      left: 16px;
    }
  }
}

.form-control {
  padding-left: 30px;
  @include placeholder(#999);
  font-size: 14px;
}

.resetpass-page {
  &.has-full-screen-bg .upper-wrapper {
    // Use our own background image
    background: $color-2 url('../images/bg-slide-3.jpg') no-repeat 50% top;
    background-size: cover;
  }
}

.setpass-page {
  &.has-full-screen-bg .upper-wrapper {
    // Use our own background image
    background: $color-2 url('../images/bg-slide-1.jpg') no-repeat 50% top;
    background-size: cover;
  }
}

.signup-page {
  &.has-full-screen-bg .upper-wrapper {
    // Use our own background image
    background: $color-2 url('../images/bg-slide-2.jpg') no-repeat 50% top;
    background-size: cover;
  }
}

.header.mini-header {
  .logo {
    float: none;
    text-align: center;
  }
}

// Copied so that it can apply to other forms outside of .access-section
.form-box {
  .form-box-inner {
    background: #fff;
    @include border-radius(4px);
    padding: 40px;
  }
  .title {
    font-weight: 300;
    margin-bottom: 60px;
    margin-top: 0;
  }
}

// Style button links as links
.btn-link {
  color: $color;
  font-weight: unset;
  padding: 0;
  vertical-align: unset;
}

// END: Velocity customizations

.app {
  background: $smoky-white;
  font-family: 'Roboto', arial, sans-serif;
  padding-bottom: 80px;
  min-height: 93vh;

  // Match the lead on the authentication forms
  .form-footer {
    .lead {
      font-size: 14px;
      color: $grey;
      margin-top: 30px;
    }
  }
}

table.contact-list {
  // Collapse the two columns into one for smaller screns
  @media only screen and (max-width: 40em) {
    thead th:not(:first-child) {
      display: none;
    }
    td, th {
      display: block;
      clear: both;
    }
    td[data-th]:before {
      float: left;
    }
  }

  tr.contact {
    cursor: pointer;
  }
}
