/*!
 * Template Name: Velocity - Bootstrap 4 Template for Promoting Startup Products
 * Version: 4.1
 * Author: Xiaoying Riley
 * Copyright: 3rd Wave Media Ltd.
 * Website: http://themes.3rdwavemedia.com/
 * Twitter: @3rdwave_themes
*/

/*************************** Theme Colours****************************/
// Define theme colour scheme
$color: #ec6952;
$theme-default: #ec6952;
$color-2: #253340;
$text-color: #444;
$text-color-secondary: #666;
$grey: #999;
$light-grey: #ccc;
$black: #000;
$darker-grey: #666;
$smoky-white: #f5f5f5;
$divider: #eee;
$ribbon: #5DA830;
$facebook: #3b5998;
$twitter: #55acee;
$google: #dd4b39;
$github: #444;


$theme-1: #ec6952;
$theme-2: #28A5A8;
$theme-3: #497CB1;
$theme-4: #F89D29;
$theme-5: #34495e;
$theme-6: #47c9af;
$theme-7: #A06081;
$theme-8: #96a94b;
$theme-9: #f06060;
$theme-10: #737F97;


/*************************** Theme Styles ****************************/
// Import theme styles
@import "theme/mixins";
@import "theme/base";
@import "theme/responsive";
//@import "theme/demo"; //REMOVE ON YOUR PRODUCTION SITE
