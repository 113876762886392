// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    
}


/* Small devices (phones, less than 768px) */
@media (max-width: 767.98px) { 
    
    .navbar-collapse {
        border-top: none;
        @include box-shadow(none);
        width: 100%;
        left:0;
        top: 15px;
        position: absolute;
        background: $color-2; 
        z-index: 45;        
        .nav {
            margin: 0;
        }
    } 
    
    .main-nav .navbar-collapse {
        .navbar-nav {
            padding-top: 15px;
            padding-bottom: 30px;
        }
        
    }
    
    .blog-page .main-nav .navbar-collapse {
        .navbar-nav {
            padding-bottom: 15px;
        }
        
    }
    
    .navbar-nav .dropdown-menu {
        position: static !important;
    }

    .config-wrapper {
        display: none;
    }
    
    #topcontrol {
        display: none !important;
    }
    
    .header h1.logo {
        float: none;
        margin: inherit;
        text-align: center;
        a {
            margin: 0 auto;
        }
    } 
    .header.header-fixed {
        position: relative;
    }
    
    .header.navbar-fixed-top {
        position: relative;
    }
     
    
    .header .main-nav .nav .dropdown-menu:before {
        display:none;
    }
    
    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: inherit;
    }
    
    .has-full-screen-bg.access-page .upper-wrapper {
        background: lighten($color-2, 10%);
    }
    
    
    .access-section {
        padding-top: 15px;
    }
    
    .headline-bg +.section, .bg-slider-wrapper +.section {
        padding-top: 45px;
    }
    
    .pricing .intro {
        margin-bottom: 45px;
    }
    
    .features-video iframe {
        padding: 5px;
    }
    
    .features-tabbed {
        padding-top: 160px;
    }
    
    .access-section {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 60px;
    }
    
    .signup-page .form-box .intro {
        margin-bottom: 45px;
    }
    
    .login-page .form-box .title {
        margin-bottom: 45px;
    }
    
    .section .title {
        font-size: 32px;
    }
    
    .section .intro {
        font-size: 16px;
    }
    
    .promo {
        height: 420px;
    }
    
    .why .item .content {
        margin-bottom: 60px;
    }
    
    .why .control {
        top: 58px;
        margin-left: -30px;
    }
    
    .why .item .title {
        margin-bottom: 30px;
        text-align: center;
    }
    
    .testimonials .item .profile {
        position: static;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
        display: block;
    }
    .testimonials .item {
        flex-direction: column;
    }
    .testimonials .item .content {
        padding-left: 60px;
    }
    
    .testimonials .item blockquote {
        font-size: 18px;
    }
    
    .pricing .item.best-buy {
        position: static;
        z-index:inherit;
    }
    
    .nav-tabs > li > a {
        padding: 10px 5px;
    }
    

    
    .blog-page .header-blog {
        position: relative;
    }
    
    
    .blog-page .header .main-nav .navbar-toggle {
        margin-bottom: 0;
        //top: 20px;

        .icon-bar {
             background-color: lighten($color-2, 10%);
         }
         &:hover {
             .icon-bar {
                 background-color: $color-2;
             }
         }
    }
    
    
    .blog-page .flexslider {
        margin-top: 0;
    }
    
    .blog-category {
        margin-top: 0;
    }
    
    
    .blog-page .header.navbar-fixed-top .main-nav .nav .nav-item a {
        color: #fff;
    }
    
    .blog-slider .flex-caption .title {
        font-size: 36px;
    }
    
    .blog-page .header-blog .main-nav .nav .nav-item a {
        color: #fff;
    }
    
    
    .searchbox-container .searchbox .searchbox-input {
        //position: static
    }
    
    .searchbox-container {
        position: relative;
        top: 15px;
    }
    
    .searchbox-container .searchbox {
        width: 100%;
        top: inherit;
        float: none;
        margin-bottom: 30px;
    }
    
    .searchbox-icon {
        display: none;
    }
    
    .searchbox-icon, .searchbox-submit {
        background: $color;
        color: #fff;
        @include border-radiuses(4px, 4px, 0, 0);
        &:hover {
            color: #fff;
        }
    }
    
    .footer-col {
        margin-bottom: 30px;
        .form-control {
            //width: auto;
        }
    }
    .footer .footer-col .signup-form {
        //padding-left: 15px;
        border: none;
        @include box-shadow(none);
        .form-control {
            display: inline-block;
        }
    }
    
    .modal.modal-video .modal-dialog {
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
    }  
    
    .download-page .headline-bg {
        height: 1600px;
    }
    
    .story-section .about {
        padding: 0;
    }
    .story-section .belief {
        margin-left: 0;
        margin-right: 0;
    }
    .story-section .team {
        padding: 0;
    }
    
    .promo {
        height: 420px;
        padding-top: 80px;
    }
    
    .header.scrolled .main-nav .nav .nav-item a {
        color: #fff;
        &:hover {
            color: $color;
        }
    } 
    
    
    
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .footer .footer-col .signup-form .btn {
	    position: relative;
	    top: 1px;
    }
    
    
}
 
 // Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .main-nav.navbar-right {
        float: right !important;
        margin-right: -15px;
    }
    
    .config-wrapper {
        display: none;
    }
    
    .header {
        height: inherit;
    }
    
    .header.scrolled {
        height: auto;
        @include box-shadow(none);
        
        .main-nav .nav .nav-item a {
            color: #fff;
            @include opacity(1); 
        }  
        .main-nav .nav .nav-item.nav-item-cta a.btn-cta-secondary {
            background: inherit;
            border-color: inherit;
        }
        .main-nav .nav .dropdown-menu a {
            color: #fff;
        }
    }

    
    .header.navbar-fixed-top {
        position: relative;
    }

    
    .blog-slider {
        margin-top: 0;
    }
    
    .blog {
        margin-top: 0;
    }
    
    .header h1.logo {
        float: none;
        text-align: center;
        a {
            margin: 0 auto;
        }
    }    
    
    .header .main-nav .nav .nav-item {
        margin-right: 10px;
    }
    
    .main-nav.navbar-right {
        float: none !important;
    }
    
    .blog-page {
        .main-nav.navbar-right {
            float: right !important;
        }
    }
    
    .navbar-collapse.collapse {
        //text-align: center;
        .navbar-nav {
            float: none;
            margin: 0 auto;
        }
    }
    
    .blog-page .header-blog {
        position: relative;
    }
    
    .blog-page .header h1.logo {
        float: left;
        margin: inherit;
    }
    
    .promo {
        height: 420px;
        padding-top: 80px;
    }
    
    .promo.section .intro {
        margin-bottom: 30px;
    }
    
    .promo .btn-link {
        margin-top: 15px;
    }
    
    .why .item .content {
        margin-bottom: 60px;
    }
    
    .why .control {
        top: 128px;
        margin-left: -45px;
    }
    
    .why .item .title {
        margin-bottom: 30px;
        text-align: center;
    }
    
    .apps-section .phone-holder {
        @include background-size(300px auto);
        .screenshot {
            width: 203px;
            top: 20px;
            left:48px;
        }
    }
    
    .footer-col {
        margin-bottom: 30px;
    }
    
    .modal.modal-video .modal-dialog {
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
    }  
    
    .access-page {
        .navbar-collapse.collapse .navbar-nav {
            display: none;
        }
    }
    
    .features-video {
        padding-top: 90px;
    }
    
    .pricing {
        padding-top: 90px;
    }
    
    .apps-section {
        padding-top: 80px;
    }
    
    .story-section {
        padding-top: 90px;
    }
    
    .contact-section {
        padding-top: 45px;
    }
    
    .footer .signup-form .form-control {
        width: 200px;
    }
    
    
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .navbar-toggleable-md .navbar-collapse {
        width: auto;
    }
    .navbar-fixed-top .navbar-collapse {
        padding-left: 15px;
        padding-right: 15px;
    }
    .navbar-toggleable-md .navbar-nav .nav-link {
        padding: 15px;
    }
    
    .config-wrapper {
        display: block;
    }
    
    .header {
        height: 80px;
    }
    
    .header.navbar-fixed-top {
        position: fixed;
    }
    
    .header.scrolled {
        //position: fixed;
        background: #fff;
        z-index: 1000;
        height: 80px;
        top: 0;
        @include box-shadow(0 0 4px rgba(0, 0, 0, 0.5));
        h1.logo a {
            @include border-radius(0);
            color: $color;
            background:#fff;
            padding-left: 0;
            padding-top: 25px;
        }
        
        .main-nav .nav .nav-item a {
            color: $text-color-secondary;
            @include opacity(1); 
        }  
        .main-nav .nav .nav-item.nav-item-cta a.btn-cta-secondary {
            background: $color;
            border-color: $color;
        }
        .main-nav .nav .dropdown-menu a {
            color: #fff;
        }
    }
    
    .main-nav {
        float: right !important;
    }
    
    
    .footer .signup-form .form-control {
        width: 280px;
    }
    
    .header h1.logo {
        float: left;
        margin: inherit;
    }    
    
    .header .main-nav .nav .nav-item {
        margin-right: 15px;
    }
    
    .main-nav.navbar-right {
        float: right !important;
    }
    
    .promo {
        height: 620px;
        padding-top: 200px;
    }
    
    .promo.section .intro {
        margin-bottom: 45px;
    }
    
    .promo .btn-link {
        margin-top: 15px;
    }
    
    .why .item .content {
        margin-bottom: inherit;
    }
    
    .why .control {
        top: 128px;
        margin-left: -28px;
    }
    
    .modal.modal-video .modal-dialog {
        width: 760px;
        padding-left: 0;
        padding-right: 0;
    } 
    
    .access-page {
        .navbar-collapse.collapse .navbar-nav {
            display: none;
        }
    }
    
    .access-section {
        .divider {
            text-align: center;
            margin-bottom: 30px;
            color: $grey;
            text-transform: uppercase;
            position:absolute;
            left: 0;
            top: 0;
            height: 100%;
            span {
                background: #fff;
                display: inline-block;
                padding: 10px 0;
                position: relative;
                top: 80px;
                margin-left: -10px;
            }
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                background: lighten($grey, 30%);
                height: 100%;
                width: 1px;
            }
    
        }
        .social-btns {
            padding-left: 65px;
            
            .note {
                text-align: left;
            }
        }
    }
    
    .blog-slider {
        margin-top: 80px;
    }

    
    .why .item .title {
        margin-bottom: 15px;
        text-align: left;
    }
    
    .why .figure {
        text-align: left;
    }
    
    .blog-page .header-blog {
        position: fixed;
    }
    
    .blog-page .blog-category, .blog-page .blog-archive {
        margin-top: 80px;
    }
    
    .blog-entry-wrapper {
        margin-top: 80px;
    }
    
    /* Only show QR code for desktop */
    .download-area {
        overflow: hidden;
        .download-list {
            float: left;
        }
        .qrcode-holder {
            float: left;
            margin-left: 45px;
            text-align: center;
            .qrcode {
                max-width: 140px;
                margin: 0 auto;
                .caption {
                    font-size: 12px;
                    background: #fff;
                    padding: 0 15px;
                    padding-bottom: 15px;
                    color: $color-2;
                    font-family: arial, sans-serif;
                }
            }
        }
    }
    
    .navbar-fixed-top .navbar-collapse {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .features-video {
        padding-top: 170px;
    }
    
    .pricing {
        padding-top: 170px;
    }
    
    .apps-section {
        padding-top: 160px;
    }
    
    .story-section {
        padding-top: 170px;
    }
    
    .contact-section {
        padding-top: 125px;
    }
    
    .bg-slider-wrapper {
        position: fixed;
    }
    
    .features-tabbed .tabs-container {
        max-width: 800px;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .apps-section .phone-holder {
        @include background-size(380px auto);
        .screenshot {
            width: 258px;
            top: 20px;
            left: 61px;
        }
    }
}

@media (min-width: 576px) {
    .modal.modal-video .modal-dialog {
        max-width: inherit;
    }
}



