$asset-path: '../..' !default;

/* ======= Base ======= */
body {
    font-family: 'Roboto', arial, sans-serif;
    color: $text-color;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 15px;
    line-height: 1.5;
}

a {
    color: $color;
    @include transition (all 0.4s ease-in-out);
    &:hover {
        text-decoration: underline;
        color: darken($color, 10%);
    }
    &:focus {
        text-decoration: none;
    }
}

.btn {
    font-size: 14px;
}

.btn, a.btn {
    @include transition (all 0.4s ease-in-out);
    font-weight: bold;

    .svg-inline--fa {
        color: #fff;
        margin-right: 5px;
    }
}


a.btn-cta, .btn-cta{
    padding: 8px 30px;
}


a.btn-cta-primary, .btn-cta-primary {
    background: $color;
    border: 2px solid $color;
    color: #fff;
    &:hover {
        background: darken($color, 5%);
        border: 2px solid darken($color, 5%);
        color: #fff;
    }
}

a.btn-cta-secondary, .btn-cta-secondary {
    background: none;
    border: 2px solid $color;
    color: $color;
    &:hover {
        background: $color;
        border: 2px solid $color;
        color: #fff;
    }
}

a.btn-ghost, .btn-ghost {
    background: none;
    border: 1px solid #fff;
    color: #fff !important;
    padding: 8px 20px;
    vertical-align: middle;
    &:hover {
        background: lighten($color-2, 20%);
        border: 1px solid lighten($color-2, 20%);
        color: #fff;
    }
    .svg-inline--fa {
        margin-right: 10px;
        font-size: 22px;
    }
    .text {
        position: relative;
        top: -2px;
    }
}

.highlight {
    color: $color;
}

.form-control {
   @include box-shadow(none);
    height: 40px;
    border-color: $divider;
    @include placeholder(lighten($grey, 5%));
    &:focus {
        border-color: darken($divider, 5%);
        @include box-shadow(none);
    }

}

textarea.form-control {
    height: auto;
}

.text-highlight {
    color: darken($color, 30%);
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
input[type="button"],
textarea,
select {
    appearance: none;  /* for mobile safari */
    -webkit-appearance: none;
}

#topcontrol {
    background: lighten($color-2, 30%);
    color: #fff;
    text-align: center;
    display: inline-block;
    width: 35px;
    height: 35px;
    border: none;
    @include border-radius(50%);
    @include transition (all 0.4s ease-in-out);
    z-index: 30;
    &:hover {
        background: lighten($color-2, 10%);
    }
    .svg-inline--fa {
        position: relative;
        top: 3px;
        font-size: 25px;
    }
}

.video-container iframe {
    max-width: 100%;
}


/* ======= Header ======= */
.header {
    color: #fff;
    width: 100%;
    position: relative;
    z-index: 40;
    height: 80px;
    .container {
        //position: static;
    }

    &.scrolled {
        height: 80px;
        top: 0;
        .main-nav .nav .nav-item a {
            color: $text-color-secondary;
            @include opacity(1);
        }
        .main-nav .nav .nav-item.nav-item-cta a.btn-cta-secondary {
            background: $color;
            border-color: $color;
        }
        .main-nav .nav .dropdown-menu a {
            color: #fff;
        }

    }

    h1.logo {
        font-family: "Roboto Slab", arial, sans-serif;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 15px;
        float: left;

        a {
            color: #fff;
            padding: 15px 30px;
            display: inline-block;
            text-align: center;
            line-height: 1.4;
            max-width: 155px;
            background: $color;
            @include border-radiuses(0, 4px, 4px, 0);
            @include transition (color 0.4s ease-in-out);
            position: relative;
            font-size: 20px;

            &:hover {
                text-decoration: none;
            }
        }
        .logo-title {
            vertical-align: middle;
            line-height: 1.6;
        }
    }

    .main-nav {
        padding: 0;
        padding-top: 15px;
        z-index: 10;
        .navbar-toggler {
            border: none;
            cursor: pointer;
            @include opacity(0.8);
            position: absolute;
            right: 0px;
            top: -50px;

            &:focus {
                outline: none;
            }

            &:hover {
                @include opacity(1);
            }
        }
        .navbar-toggler-icon {
            background: transparent url($asset-path + '/images/hamburger-icon.svg') no-repeat center center;
        }

        .navbar-collapse {
            padding: 0 15px;
        }

        .nav {
            .nav-item {
                font-weight: normal;
                text-transform: uppercase;
                font-size: 13px;
                margin-right: 15px;

                &.active {
                    a {
                        color: $color;
                        background: none;
                        font-weight: bold;
                    }
                }
                &.nav-item-cta {
                    a.btn-cta-secondary {
                        border: 1px solid #fff;
                        color: #fff;
                        padding: 8px 10px;
                        margin-top: 5px;
                        font-size: 13px;
                        &:hover {
                            border: 1px solid $color;
                            background: $color;
                        }
                    }
                }
                a {
                    color: #fff;
                    padding: 15px;
                    display: block;
                    &:hover {
                        color: $color;
                        background: none;
                    }
                    &:focus {
                        outline: none;
                        background: none;
                    }
                    &:active {
                        outline: none;
                        background: none;
                    }
                    &.dropdown-toggle:after {
                        display: none;

                    }
                }
                &.active {
                    color: $color;
                }
                &.last {
                    margin-right: 0;
                }




            }

            &.active {
                position: relative;
                & > a {
                    background: darken($color, 10%);
                    color: #fff;
                    color: rgba(256, 256, 256, 0.8);
                    &:before {
                        content: "\f0d8";
                        position: absolute;
                        bottom: -8px;
                        left: 0;
                        text-align: center;
                        font-family: FontAwesome;
                        display: block;
                        font-size: 12px;
                        width: 100%;
                    }
                    &.dropdown-toggle:before {
                        display: none;
                    }
                }
            }
            &.open {
                a {
                    background: none;
                }
            }
            .dropdown-menu {
                @include border-radius(4px);
                margin: 0;
                border:none;
                text-transform: none;
                min-width: 180px;
                background: $color-2;

                &:before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $color-2;
                    position: absolute;
                    right: 30px;
                    top: -10px;
                }

                a {
                    border-bottom: 1px solid darken($color-2, 2%);
                    padding: 8px 20px;
                    color: #fff;
                    @include opacity(1);
                    font-size: 14px;

                    &:hover {
                        background: lighten($color-2, 5%);
                        color: #fff;
                    }
                }
            }

        }
    }

}

/* Override bootstrap default */
.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
    background: none;
}

a.dropdown-toggle {
    .svg-inline--fa {
        margin-left: 5px;
    }
}

/* Hover dropdown menu */
.dropdown:hover>.dropdown-menu {
  display: block;
}

/* ======= Headline Background & Slideshow ======= */

.bg-slider-wrapper {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 780px;
    background: darken($text-color, 5%);

    .flexslider {
        margin: 0;
        @include box-shadow(none);
        border: none;
        @include border-radius(0);

        .slide {
            display: block;
            height:780px;
            &.slide-1 {
                background:$color-2 url($asset-path + '/images/background/bg-header-1.jpg') no-repeat 50% top;
                @include background-size(cover);
            }
            &.slide-2 {
                background:$color-2 url($asset-path + '/images/background/bg-header-2.jpg') no-repeat 50% top;
                @include background-size(cover);
            }
            &.slide-3 {
                background:$color-2 url($asset-path + '/images/background/bg-header-3.jpg') no-repeat 50% top;
                @include background-size(cover);
            }

        }
        .flex-control-nav {
            z-index:30;
            bottom: 30px;
            position: relative;
            li a {
                background: $grey;
                &.flex-active {
                    background: lighten($grey, 15%);
                }
            }
        }
    }
}

.headline-bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 500px;
    background: $color-2 url($asset-path + '/images/background/bg-header-small-1.jpg') no-repeat 50% top;
    @include background-size(cover);
    &.pricing-headline-bg {
        background-image: url($asset-path + '/images/background/bg-header-small-2.jpg');
    }
    &.contact-headline-bg {
        background-image: url($asset-path + '/images/background/bg-header-small-3.jpg');
    }
    &.about-headline-bg {
        background-image: url($asset-path + '/images/background/bg-header-small-4.jpg');
    }


}


/* ======= Promo ======= */
.promo {
    padding-top: 120px;
    position: relative;
    color: #fff;
    margin-bottom: 80px;
    height: 620px;
    z-index: 10;

    .title {
        font-size: 46px;
        margin-bottom: 20px;
        margin-top: 0;
        font-weight: 700;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    &.section .intro {
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .btn-cta {
        font-size: 18px;
    }

    .btn-link {
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        @include opacity(0.7);
        text-decoration: none;
        border: none;
        background: none;
        //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
        &:hover {
            @include opacity(1);
            text-decoration: none;
        }
        &:focus {
            outline:none;
        }
        .svg-inline--fa {
            margin-right: 5px;
            font-size: 18px;
            position: relative;
            top: 2px;
        }
    }
}

/* ======= sections-wrapper ======= */
.sections-wrapper {
    background: #fff;
    z-index: 20;
    position: relative;
}
.section .intro {
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 60px;
    text-align: center;
    color: $grey;
    font-size: 18px;
}

.section-on-bg {
    .title {
        color: #fff;
    }
    .intro {
        color: #fff;
        @include opacity(0.8);
    }
}

/* ======= Why ======= */
.why {
    padding-top: 80px;
    padding-bottom: 80px;
    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
    }
    .intro {
        color: $grey;
        margin-bottom: 30px;
        font-size: 18px;
    }
    .item {
        padding: 90px 0;
        border-bottom: 1px solid $divider;
        &.last-item {
            border-bottom: none;
        }
        .title {
            font-size: 26px;
            color: lighten($color-2, 20%);
            margin-top: 0;
            margin-bottom: 15px;
        }
        .desc {
            margin-bottom: 60px;
            .svg-inline--fa {
                margin-right: 5px;
            }
        }
        .quote {
            position: relative;
            .quote-profile {
                position: absolute;
                left: 0;
            }
            .quote-content {
                margin-left: 90px;
                background: $smoky-white;
                border-left: 4px solid darken($smoky-white, 5%);
                @include border-radius(4px);
                font-size: 14px;
                padding: 15px;
                &:before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-right: 10px solid darken($smoky-white, 5%);
                    position: absolute;
                    left: 80px;
                    top: 25px;
                }
            }
            blockquote {
                border-left: none;
                font-style:italic;
                color: $text-color-secondary;
                font-size: 16px;
                padding: 0;
                margin-bottom: 10px;
            }
            .source {
                color: $grey;
                font-size: 13px;
                margin-bottom: 0;
                a {
                    color: $grey;
                    &:hover {
                        color: darken($grey, 10%);
                    }
                }
            }
        }
    }

    .figure {
        position: relative;
        text-align: center;
        .figure-caption {
            color: lighten($grey, 10%);
            font-size: 13px;
            margin-top: 10px;
            text-align: center;
            a {
                color: lighten($grey, 10%);
                &:hover {
                    color: $color;
                }
            }
        }
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .control {
        position: absolute;
        top: 120px;
        left: 50%;
        margin-left: -65px;
        .play-trigger {
            color: #fff;
            background: rgba(0, 0, 0, 0.3);
            position:relative;
            width: 60px;
            height: 60px;
            border: 4px solid #fff;
            @include border-radius(50%);
            margin: 0 auto;
            text-align: center;
            @include opacity(0.9);
            @include transition (all 0.4s ease-in-out);
            &:active, &:focus {
                outline:none;
            }

            &:hover {
                color: #fff;
                @include opacity(1);
                background: rgba(0, 0, 0, 0.6);
                .svg-inline--fa {
                    cursor: pointer;
                }
            }
            .svg-inline--fa {
                font-size: 22px;
                position: relative;
                left:3px;
                top: 2px;
            }
        }
    }

    .feature-lead {
        .title {
            font-weight: 300;
            font-size: 16px;
            margin-bottom: 15px;
        }
    }

}

/* ======= Testimonials ======= */
.testimonials {
    background: $smoky-white;
    padding-top: 60px;
    padding-bottom: 120px;
    .carousel-indicators {
        bottom: -60px;
        z-index: auto;
        li {
           background: $light-grey;
           border: none;
           margin: 3px;
           height: 4px;
           @include border-radius(2px);
            &.active {
                background: lighten($color, 10%);
                border: none;
                //width: 10px;
                //height: 10px;
                //margin: 1px;
            }
        }

    }
    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 60px;
    }
    .item {
        .profile {
            position: absolute;
            left: 0;
        }
        .content {
            padding-left: 210px;
        }
        blockquote {
            padding: 0;
            font-size: 20px;
            font-weight: 300;
            border-left: 0;
            padding: 0;
            position: relative;

            .svg-inline--fa {
                position: absolute;
                color: lighten($color, 5%);
                font-size: 22px;
                left: -45px;
            }
        }
        .source {
            color: $color;


            .title {
                font-size: 16px;
                color: $text-color-secondary;
            }
        }
    }
}

/* ======= Press ======= */
.press {
    padding-top: 80px;
    padding-bottom: 80px;
    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 60px;
    }
    .press-list {
        margin-bottom: 15px;
        text-align: center;
        &.last {
            margin-bottom: 60px;
        }
        li {
            display: block;
            height: 80px;
            padding-left: 5px;
            padding-right: 5px;
            img {
                @include opacity(0.6);
            }
            &:hover {
                img {
                    @include opacity(1);
                }
            }
        }

    }
    .press-lead {
        .title {
            font-size: 18px;
            margin-bottom: 15px;
        }
    }
}

/* ======= cta-section ======= */
.cta-section {
    background: $color-2 url($asset-path + '/images/background/bg-footer-1.jpg') no-repeat left top;
    background-size: cover;
    min-height: 420px;
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
    border: none;

    &.pricing-cta-section {
        background-image: url($asset-path + '/images/background/bg-footer-2.jpg');
    }

    &.contact-cta-section {
        background-image: url($asset-path + '/images/background/bg-footer-3.jpg');
    }

    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 60px;
    }
    .btn-cta {
        font-size: 20px;
    }
    .intro {
        margin-bottom: 15px;
        color: #fff;
    }
    .counting {
        color: $color;
        font-weight: bold;
        @include opacity(1);
    }
}

/* ======= Features video ======= */
.features-video {
    padding-top: 170px;
    position: relative;
    z-index: 10;
    .title {
        font-size: 36px;
        margin-bottom: 60px;
        margin-top: 0;
        color: #fff;
        font-weight: 300;
    }
    .video-container {
        max-width: 800px;
        margin: 0 auto;

    }
    iframe {
        background: #fff;
        padding: 15px;
        @include border-radius(4px);
        border:1px solid $divider;
        @include box-shadow(0 4px 1px rgba(0,0,0,.05));
    }
}

/* ======= Features tabs ======= */

.features-tabbed {
    padding-top: 80px;
    padding-bottom: 80px;
    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 60px;
    }

    .tab-content {
        padding: 60px 30px;
        .title {
            font-size: 16px;
            color: lighten($color, 10%);
            font-weight: bold;
            margin-bottom: 30px;
        }
        .desc {
            margin-bottom: 30px;
            color: $text-color-secondary;
            ul {
                padding-left: 30px;
                padding-top: 15px;
                padding-bottom: 15px;
                li {
                    margin-bottom: 10px;
                }
                .svg-inline--fa {
                    color: $color;
                    margin-right: 5px;
                }
            }
            blockquote {
                font-style:italic;
                color: lighten($text-color-secondary, 10%);
            }
            .table {
                font-size: 14px;
                padding: 15px 0;
            }
            .box {
                background: $smoky-white;
                padding: 30px;
                font-size: 14px;
            }
        }
        .figure {
            margin-bottom: 60px;
            img {
                @include box-shadow(0px 0px 5px 0px rgba(0, 0, 0, 0.2));
                margin: 0 auto;
            }
            .figure-caption {
                color: lighten($grey, 10%);
                font-size: 13px;
                margin-top: 10px;
                text-align: center;
                a {
                    color: lighten($grey, 10%);
                    &:hover {
                        color: $color;
                    }
                }
            }
        }
    }
}

.nav-tabs {
    border: none;
    margin: 0 auto;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
    text-align: center;
    position: relative;

   &:before {
       content: "";
       display: block;
       border-bottom: 1px solid $divider;
       position: absolute;
       bottom: 0;
   }
   .nav-item {
       margin-bottom: -1px;
       position: relative;
       z-index:1;
       background: #fff;

       -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
       -moz-box-flex: 1;         /* OLD - Firefox 19- */
       width: 20%;               /* For old syntax, otherwise collapses. */
       -webkit-flex: 1;          /* Chrome */
       -ms-flex: 1;              /* IE 10 */
       flex: 1;

       border: 1px solid $divider;
       border-right: none;
       border-bottom: none;

       .nav-link {
           @include border-radius(0);
           margin-right: 0px;
           color: $grey;
           padding: 15px 30px;
           font-size: 14px;
           background: lighten($smoky-white, 2%);
           border: none;
           border-bottom: 1px solid $divider;
           color: lighten($text-color-secondary, 10%);
           background: #fff;

           &:hover, &:focus {
               color: $text-color-secondary;
           }

           &.active {
               @include box-shadow(inset 0 3px 0 $color);
               border-bottom-color: #fff;
               color: $color;

               &:hover {

                   color: $color;
                   background: none;
               }
           }

           .svg-inline--fa {
               font-size: 20px;
           }

           &:hover {
               background: $smoky-white;
           }

       }

       &.last {
           border-right: 1px solid $divider;
       }

   }

}

/* ======= Steps ======= */
.steps {
    background: $smoky-white;
    padding-top: 80px;
    padding-bottom: 120px;

    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 60px;
    }

    .step {
        padding: 30px 60px;
        color: $text-color-secondary;
        .title {
            text-align: center;
            font-size: 16px;
            margin-bottom: 15px;
            .number {
                width: 90px;
                height: 90px;
                display: block;
                @include border-radius(50%);
                text-align: center;
                background: $color;
                border: 5px solid lighten($color, 10%);
                color: #fff;
                margin: 0 auto;
                padding-top: 25px;
                font-size: 26px;
                font-weight: bold;
                font-family: arial, sans-serif;
                margin-bottom: 30px;
            }
            .text {
                color: lighten($color, 10%);
                font-weight: bold;
                font-size: 16px;
            }
        }
    }

    .btn-cta-primary {
        margin: 0 auto;
        margin-top: 60px;
        font-size: 20px;
    }

}

/* ======= Pricing ======= */
.pricing {
    padding: 80px 0;
    padding-top: 170px;
    position: relative;
    z-index: 10;

    .price-cols {
        max-width: 85%;
    }

    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;
        color: #fff;
    }
    .intro {
        color: #fff;
        margin-bottom: 80px;
    }
    .item {
        padding: 0;
        @include border-radius(4px);
        margin-bottom: 30px;
        &.best-buy {
            top: -15px;
            z-index: 11;
            position: relative;
            .item-inner {
                @include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.2));
                &:hover {
                    @include box-shadow(0px 0px 20px 0px rgba(0, 0, 0, 0.25));
                }
            }
            .heading {
                padding-top: 45px;
            }
            .content {
                padding-bottom: 45px;
            }
        }

        .item-inner {
            background: #fff;
            @include border-radius(4px);
            position: relative;
            border: 1px solid $divider;
            @include box-shadow(0 4px 1px rgba(0,0,0,.05));
            &:hover {
                @include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.2));
            }
        }

        .heading {
            margin-top: 0;
            padding: 30px 15px;
            @include border-radiuses(4px, 0, 0, 4px);
            .title {
                margin-bottom: 30px;
                padding-bottom: 30px;
                font-weight: 400;
                font-size: 18px;
                border-bottom: 1px dashed $divider;
                color: $text-color;
            }
        }
        .content {
            padding: 30px 15px;
            padding-top:0;
            font-size: 14px;
        }
        .price-figure {
            @include border-radius(50%);
            margin: 0 auto;

            .number {
                font-size: 42px;
                font-weight: bold;
            }
            .unit {
                font-weight:400;
                color: lighten($grey, 10%);
            }
            .currency {
                top: -12px;
                right: 2px;
                position: relative;
                font-family: arial, sans-serif;
                color: $text-color-secondary;
                font-size: 18px;

            }

        }

        .ribbon {
            position: absolute;
            top: -6px;
            right: -6px;
            width: 110px;
            height: 110px;
            overflow: hidden;
            .text {
                position: relative;
                left: -8px;
                top: 18px;
                width: 158px;
                padding: 10px 10px;
                font-size: 15px;
                font-weight: bold;
                text-align: center;
                text-transform: uppercase;
                color: #fff;
                background-color: lighten($color-2, 30%);
                @include transform (rotate(45deg) translate3d(0,0,0));
                &:before, &:after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    border-top: 5px solid lighten($color-2, 5%);
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                }
                &:before {
                    left: 0;
                }
                &:after {
                    right: 0;
                }
            }
        }

        .feature-list {
            margin-bottom: 30px;
            li {
                border-bottom: 1px solid lighten($divider, 2%);
                padding: 10px;
                .svg-inline--fa {
                    margin-right: 5px;
                    color: lighten($color-2, 20%);
                }
                &.disabled {
                    color: $grey;
                    .svg-inline--fa {
                        color: $grey;
                    }
                }
            }

        }

    }
}

/* ======= Download Apps ======= */
.download-page {
    .headline-bg {
        height: 1200px;
        background:$color-2  url($asset-path + '/images/background/bg-mobile-headline.jpg') no-repeat 50% top;
        @include background-size(cover);
    }
}
.apps-section {
    min-height: 900px;
    padding-top: 160px;
    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 15px;
        color: #fff;
    }
    .intro {
        text-align: left;
        margin-bottom: 60px;
    }
    .phone-holder-wrapper {
        //padding-left: 60px;
    }
    .phone-holder {
        width: 345px;
        height: 651px;
        display: block;
        background: url($asset-path + '/images/mobile/iphone.png') no-repeat left top;
        position: relative;
        .screenshot {
            position: absolute;
            left: 58px;
            top: 75px;
        }
    }

    .content-area {
        .download-list {
            li {
                margin-bottom: 15px;

                .btn {
                    width: 220px;
                    text-align: left;
                }
            }
        }
        .info {
            margin-top: 60px;
            margin-bottom: 60px;
            background: rgba(31, 51, 64, 0.8);
            padding: 30px;
            color: #fff;
            @include border-radius(4px);
            .title {
                margin-top: 15px;
                margin-bottom: 30px;
                font-size: 24px;
                font-weight: normal;
            }

        }
    }
}



/* ======= FAQ Section ======= */

.faq {
    background: $smoky-white;
    padding-top: 80px;
    padding-bottom: 120px;

    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 60px;
    }

    .card {
        background: #fff;
        @include box-shadow(0 4px 1px rgba(0,0,0,.05));
        border: none;
        margin-bottom: 20px;

        &:hover {
            @include box-shadow(0 4px 1px rgba(0, 0, 0, .1));
        }

        .card-header {
            padding: 15px 30px;
            background: none;
            border: none;
        }
        .card-block{
            padding: 30px;
            padding-top: 0;
            color: $text-color-secondary;
        }
        .card-title {
            font-weight: normal;
            font-size: 16px;
            color: $text-color-secondary;
            a {
                text-decoration: none;
                color: $text-color-secondary;
            }
            .active {
                color: $color;
            }
            .card-toggle {
                .svg-inline--fa {
                    margin-right: 10px;
                    color: lighten($color, 10%);
                }
            }
            &:hover {
                .svg-inline--fa {
                    color: $color;
                }
            }
        }
    }

    .contact-lead {
        margin-top: 60px;
        .title {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 15px;
            color: $grey;
        }
    }

}

/* ======= Contact section ======= */
.contact-section {
    padding: 80px 0;
    padding-top: 90px;
    position: relative;
    z-index: 10;

    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;
    }
    .intro {
        margin-bottom: 60px;
    }
    .contact-form {
        margin-left: 15px;
        margin-right: 15px;
        .contact-form-inner {
            background: #fff;
            padding: 30px;
            @include border-radius(4px);
            border:1px solid $divider;
            @include box-shadow(0 4px 1px rgba(0,0,0,.05));
        }
        .error {
            color: $color;
            font-family: arial, sans-serif;
            display: block;
            font-size: 14px;
            text-align: left;
            padding-top: 5px;
            padding-left: 5px;
            font-weight: 300;
        }
    }
}

/* ======= Contact-other section ======= */
.contact-other-section {
    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;

    }
    .other-info {

        li {
            margin-bottom: 15px;
            padding: 15px;
            position: relative;
            color: $text-color-secondary;
            text-align: left;
            padding-left: 90px;
            @include box-shadow(0 4px 1px rgba(0,0,0,.05));
            border: 1px solid $divider;
            @include border-radius(4px);
            a {
                color: $text-color-secondary;
                &:hover {
                    color: $color;
                }
            }
            .icon-holder {
                position: absolute;
                left: 0;
                top: 0;
                background: $color;
                color: #fff;
                width: 60px;
                height: 100%;
                font-size: 20px;
                padding-top: 12px;
                text-align: center;
                @include border-radiuses (0, 0, 4px, 4px);

            }
        }

    }

}

/* ======= Map section ======= */
.map-section {
    padding-top: 80px;
    padding-bottom: 80px;
    .title {
        font-size: 36px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;
    }
    .gmap-wrapper {
        border: 1px solid $divider;
        position: relative;
        padding-bottom: 40%; // This is the aspect ratio
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }
}


/* ======= Blog Slideshow ======= */
.blog-slider {
    margin-bottom: 60px;
    margin-top: 80px;
    border: none;
    height: 400px;
    @include border-radius(0);
    @include box-shadow(none);

    .slide {
        display: block;
        height:400px;
        background-color: $color-2;
        &:after {
            content:"";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.55);
            z-index: 10;
        }
        &.slide-1 {
            background:$color-2 url($asset-path + '/images/blog/post-1.jpg') no-repeat 50% top;
            @include background-size(cover);
        }
        &.slide-2 {
            background:$color-2 url($asset-path + '/images/blog/post-2.jpg') no-repeat 50% top;
            @include background-size(cover);
        }
        &.slide-3 {
            background:$color-2 url($asset-path + '/images/blog/post-3.jpg') no-repeat 50% top;
            @include background-size(cover);
        }
        &.slide-4 {
            background:$color-2 url($asset-path + '/images/blog/post-4.jpg') no-repeat 50% top;
            @include background-size(cover);
        }

    }

    .flex-control-nav {
        bottom: 15px;
        z-index:10;
        li a {
            background: lighten($text-color, 10%);
            @include opacity(0.8);
            &.flex-active  {
                background: #fff;

            }
        }
    }
    .flex-direction-nav a {
        text-shadow:none;
        color: #fff;
        position: absolute;
        width: 30px;
        height: 50px;
        display: block;
        &.flex-prev {
            left: 5%!important;
            &:before {
                content: "";
                text-indent: -9999px;
                background: url($asset-path + '/images/flexslider/carousel-arrow-prev.png') no-repeat left top;
                display: block;
                width: 30px;
                height: 50px;
            }
        }

        &.flex-next {
            right: 5%!important;
            &:before {
                content: "";
                text-indent: -9999px;
                background: url($asset-path + '/images/flexslider/carousel-arrow-next.png') no-repeat right top;
                display: block;
                width: 30px;
                height: 50px;
            }
        }
    }


    &:hover {
        .flex-prev{
            left: 5%!important;
        }
        .flex-next{
            right: 5%!important;
        }
    }

    .flex-caption {
        padding-top: 90px;
        position: relative;
        z-index: 20;
        text-align: center;
        color: #fff;
        a {
            color: #fff;
        }
        .title {
            font-size: 42px;
            text-align: center;
            max-width: 800px;
            margin: 0 auto;
            margin-bottom: 15px;
            margin-top: 0;
            a:hover {
                text-decoration: none;
            }
        }
        .meta {
            @include opacity(0.6);
            margin-bottom: 30px;
        }
        a.more-link {
            color: #fff;
            &:hover {
                color: $color;
            }
        }
    }

}

/* Blog homepage */
.blog-nav {
    .nav-item {
        position: relative;
    }
}
.blog-list {
    .post {
        padding: 0 15px;
        margin-bottom: 30px;
        .post-inner {
            padding: 0px;
            position: relative;
            border: 1px solid $divider;
            @include border-radius(4px);
            @include box-shadow(0 4px 1px rgba(0,0,0,.05));
        }

        .post-thumb {
            background: $color-2;
            overflow: hidden;
            @include border-radiuses(4px, 0, 0, 4px);
            margin-bottom: 0;
            img {
                @include opacity(1);
                @include transition(all .2s ease-in-out);
                @include border-radiuses(4px, 0, 0, 4px);
                margin: 0 auto;
                text-align: center;

                &:hover {
                    @include transform (scale(1.1));
                    @include opacity(0.6);
                    @include transition(all .4s ease-in-out);
                }

            }

        }
        .content {
            padding: 30px;
            padding-bottom: 15px;
            color: $text-color-secondary;

            .post-title {
                margin-top: 0;
                font-size: 18px;
                a {
                    color: $color-2;

                }

            }
            .post-entry {
                margin-bottom: 15px;
            }
            .meta {
                font-size: 13px;
                color: $grey;
                margin-bottom: 0;
                ul {
                    margin-left: 0;
                    border-top: 1px solid $divider;
                    padding-top: 15px;
                    margin-bottom: 0;
                }
                li {
                    &:first-child {
                        padding-left: 0;
                    }
                    &.post-author {

                    }
                    .svg-inline--fa {
                        margin-right: 5px;
                    }
                    a {
                        color: $grey;
                        &:hover {
                            color: $color;
                        }
                    }
                }
            }
        }
    }


}

/* Single blog post */

.blog-entry-wrapper {
     padding-bottom: 80px;
     .blog-entry {
         .blog-entry-heading {
            margin-bottom: 60px;
            position: relative;
            height: 500px;
            background:$color-2 url($asset-path + '/images/blog/post-2.jpg') no-repeat 50% top;
            @include background-size(cover);
            color: #fff;
            &:after {
                content:"";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 10;
            }
            .container {
                position: relative;
                z-index: 11;
                max-width: 700px;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 120px;
            }
             .title {
                 margin-bottom: 30px;
                 font-size: 42px;
             }
             .meta {
                 font-size: 18px;
                 @include opacity(0.8);
                 a {
                     color: #fff;
                     &:hover {
                         color: #fff;
                     }
                 }
                 .svg-inline--fa {
                     margin-right: 5px;
                 }

             }
             .post-nav-top {
                position: absolute;
                z-index: 11;
                width: 100%;
                bottom: 0px;
                background: rgba(0, 0, 0, 0.5);
                padding: 15px 30px;
                margin-bottom: 0;
                font-size: 14px;

                a {
                    color: #fff;
                    @include opacity(0.6);
                    &:hover {
                        @include opacity(1);
                    }
                }
            }

         }
         .blog-entry-content {
             font-size: 18px;
             line-height: 1.6;
             color: $text-color-secondary;
             h1, h2, h3, h4, h5, h6 {
                 color: lighten($color-2, 20%);
                 &.section-heading {
                     margin-top: 60px;
                     margin-bottom: 30px;
                 }
             }
             p {
                 margin-bottom: 30px;
             }
             ul, ol {
                 margin-bottom: 30px;
                 li {
                     margin-bottom: 15px;
                 }
             }
             .figure {
                 margin-bottom: 30px;
                 .figure-caption {
                     font-size: 16px;
                     color: $grey;
                     margin-top: 10px;
                     a {
                         color: $grey;
                         &:hover {
                             color: $color;
                         }
                     }
                 }
             }
             .custom-list-style {
                margin-bottom: 30px;
                li {
                    list-style: none;
                    margin-bottom: 5px;
                    .svg-inline--fa {
                        margin-right: 10px;
                        color: $color;
                    }
                }
            }
         }
         .post-nav {
            margin-bottom: 60px;
            margin-top: 60px;
            .nav-next {
                float: right;
                .svg-inline--fa {
                    margin-left: 5px;
                }
            }
            .nav-previous {
                .svg-inline--fa {
                    margin-right: 5px;
                }
            }
        }
     }
    .custom-quote {
        border: none;
        padding: 60px 30px;
        font-family: 'Roboto slab', serif;
        font-weight: 300;
        font-style: italic;
        font-size: 20px;
        p {
            color: $text-color-secondary;
            line-height: 1.6;
        }
        .svg-inline--fa {
            color: $color;
            margin-right: 10px;
        }
        .source {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: normal;
            margin-bottom: 0 !important;
            font-size: 16px;
            text-align: right;

            .svg-inline--fa {
                margin-right: 5px;
            }
            .name {
                color: $text-color;
            }
            .title {
                font-size: 16px;
                color: $grey;
            }
        }
    }
    .box {
        padding: 30px;
        background: $smoky-white;
    }
}

.blog-page {
    .header-blog {
        position: fixed;
        top: 0;
        @include box-shadow(0 0 4px rgba(0, 0, 0, 0.5));
        background: #fff;
        height: 80px;
        .main-nav .nav .nav-item a {
            color: $text-color-secondary;
            &:hover {
                color: $color;
            }
        }
        .main-nav .nav .dropdown-menu a {
            color: #fff;
        }
        .main-nav .navbar-toggler {
            //top: 18px;
        }
    }
    .header-blog h1.logo {
        position: relative;
        z-index: 10;
    }
    .header-blog h1.logo a {
        max-width: inherit;
        background: none;
        color: $color;
        padding-left: 0;
        padding-top: 25px;
        .sub {
            font-family: "Roboto", arial, sans-serif;
            color: $color-2;
            font-weight: 300;
        }
    }
    .header-blog .main-nav .nav .nav-item.last {
        margin-right: 40px;
        .svg-inline--fa {
            font-size: 16px;
            margin-right: 5px;
        }
    }


}

/* Blog category page */

.blog-category{
    margin-top: 160px;
    .page-title {
        margin-top: 0;
        margin-bottom: 60px;
        font-weight: 300;
        font-size: 36px;
        padding-top: 80px;
        .svg-inline--fa {
            color: lighten($color-2, 10%);
            margin-right: 10px;
            font-size: 30px;
        }
    }
    .blog-list .post .content .post-title {
        font-size: 24px;
    }
    .blog-list .post .content .meta ul {
        border-top: none;
        border-bottom: 1px solid $divider;
        padding-bottom: 15px;
        padding-top: 5px;
    }
    .blog-category-list {
        .post {
            margin-bottom: 60px;
        }
    }

}

/* Blog archive page */

.blog-archive {

    .blog-list .post .content {
        padding-left: 80px;
    }

    .post {
        position: relative;
        .date-label {
             background: $smoky-white;
             display: inline-block;
             width: 50px;
             height:60px;
             text-align: center;
             font-size: 13px;
             position: absolute;
             left: 15px;
             top: 30px;
             color: #fff;
             .month {
                 background: $color-2;
                 display: block;
                 font-size: 13px;
                 text-transform: uppercase;
                 padding: 2px 0;
             }

             .date-number {
                 clear:left;
                 display: block;
                 padding-top: 5px;
                 font-size: 18px;
                 font-family: 'open sans', arial, sans-serif;
                 font-weight: 500;
                 color: $color-2;
             }

        }

    }

}

/* ======= About Page ======= */
.story-section {
    padding-top: 90px;
    position: relative;
    z-index: 10;
    .title {
        font-size: 36px;
        margin-bottom: 60px;
        margin-top: 0;
        color: #fff;
        font-weight: 300;
    }
    .story-container-inner {
        max-width: 900px;
        margin: 0 auto;
        background: #fff;
        padding: 30px;
        @include border-radius(4px);
        text-align: left;
    }
    .about {
        padding: 30px;
    }
    .belief {
        border-left:5px solid lighten($color, 10%);
        padding: 15px 30px;
        font-family: 'Roboto slab', serif;
        font-weight: 300;
        font-style: italic;
        font-size: 20px;
        margin: 60px 30px;
        color: $text-color-secondary;
    }
    .press-kit {
        padding-top: 60px;
        padding-bottom: 60px;
        .title {
           color: $text-color;
           font-size: 28px;
        }
        .btn {
            margin: 30px 0;
        }

    }
    .team {
        max-width: 900px;
        margin: 0 auto;
        padding: 30px;
        text-align: center;
        .title {
            color: $text-color;
            font-size: 28px;
        }
        .member {
            margin-bottom: 30px;
            .member-inner {
                background: $color-2;
                @include border-radius(4px);
                position: relative;
                @include transition (all 0.4s ease-in-out);
                text-align: center;
                img {
                    margin: 0 auto;
                }
                &:hover {
                    img {
                        @include opacity(0.8);
                    }
                    .social {
                        visibility:visible;
                    }
                }
            }

            .profile {
                background: $color-2;
                color: #fff;
                text-align: center;
                .info {
                    padding: 15px 0;
                }
                .name {
                    @include opacity(0.6);
                    font-size: 18px;
                }
                .job-title {
                    color: #fff;
                    font-size: 14px;
                }
            }

            .social {
                width: 100%;
                top: 50%;
                position: absolute;
                visibility: hidden;
            }
            .social-list {
                display: inline-block;
                margin: 0 auto;

                li {
                    padding: 0 5px;
                }

                a {
                    color: #fff;
                    background: $color-2;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    @include border-radius(50%);
                    .svg-inline--fa {
                        padding-top: 10px;
                        font-size: 28px;
                    }
                    &:hover {
                        background: $color;
                    }
                }
            }
        }
    }
}


/* ======= Modal ======= */
.modal {

    &.modal-video {
        .modal-dialog {
            //max-width: 80% !important;
            margin-top: 90px;
        }
        .modal-body {
            padding: 0;
            padding-top: 0;
        }
        .modal-header {
            border: none;
            padding: 0;
            position: relative;
        }
        .modal-content {
            background: none;
            border: none;
            @include box-shadow(none);
        }

        button.close {
            font-size: 36px;
            font-weight:300;
            text-shadow:none;
            color: #fff;
            @include opacity(1);
            background: none;
            position: absolute;
            right: 0px;
            bottom: 5px;
            z-index:10;
            &:hover {
                color: $color;
                @include opacity(1);
            }
        }

    }
}

.modal-backdrop.in {
    @include opacity(0.9);
}

/* ======= Login Page ======= */
.has-full-screen-bg {
    .upper-wrapper {
        background:$color-2 url($asset-path + '/images/background/bg-screen-1.jpg') no-repeat 50% top;
        @include background-size(cover);
    }
    &.signup-page {
        .upper-wrapper {
            background:$color-2 url($asset-path + '/images/background/bg-screen-2.jpg') no-repeat 50% top;
            @include background-size(cover);
        }
    }
    .header h1.logo {
        float: none;
        text-align: center;
        a {
            margin: 0 auto;
        }
    }
}

.access-section {
    padding-bottom: 200px;
    padding-top: 90px;
    padding-left: 10px;
    padding-right: 10px;
    .form-box {
        .form-box-inner {
            background: #fff;
            @include border-radius(4px);
            padding: 40px;
        }
        .title {
            font-weight: 300;
            margin-bottom: 60px;
            margin-top: 0;
        }
    }

    &.signup-section {
        .title {
            margin-bottom: 15px;
        }
        .intro {
            margin-bottom: 60px;
        }
    }

    .form-group {
        position: relative;
        &.email {
            .svg-inline--fa {

                position: absolute;
                left: 10px;
                top: 13px;
                color: $grey;
                font-size: 13px;
            }
        }
        &.password {
            .svg-inline--fa {

                position: absolute;
                left: 10px;
                top: 13px;
                color: $grey;
                font-size: 13px;
            }
        }
    }
    .form-control {
        font-size: 16px;
        padding-left: 30px;
        @include placeholder(#999);
        font-size: 14px;
    }
    .social-btns {
        padding-left: 60px;
        min-height: 250px;

        li {
            margin-bottom: 10px;
            .btn {
                color: #fff;
                min-width: 220px;
                text-align: left;
                &:hover {
                    color: #fff;
                }
            }
            .btn .svg-inline--fa{
                font-size: 18px;
                position: relative;
                top: 2px;
                margin-right: 10px;
                border-right: 1px solid rgba(0, 0, 0, 0.05);
                padding-right: 10px;
                width: 30px;
            }
        }
        margin-bottom: 15px;
    }
    .twitter-btn {
        background: $twitter;
        &:hover {
            background: darken($twitter, 10%);
        }
    }
    .facebook-btn {
        background: $facebook;
         &:hover {
            background: darken($facebook, 10%);
        }
    }
    .google-btn {
        background: $google;
         &:hover {
            background: darken($google, 10%);
        }
    }
    .github-btn {
        background: $github;
         &:hover {
            background: darken($github, 10%);
        }
    }
    .forgot-password {
        font-size: 13px;
        margin-top: 10px;
        a {
            color: $grey;
            &:hover {
                text-decoration: underline;
                color: $color;
            }
        }
    }

    .divider {
        border-left: none;
        margin-bottom: 30px;
        position: static;
        span {
            background: #fff;
            display: inline-block;
            padding: 0 10px;
            position: relative;
            top: inherit;
            text-transform: uppercase;
            color: $grey;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 12px;
            background: lighten($grey, 30%);
            height: 1px;
            width: 100%;
        }

    }

    .social-btns {
        padding-left: 0;
        text-align: center;
        .btn {
            min-width: 100%;
        }
        .social-login {
            display: inline-block;
            margin: 0 auto;
        }
    }

    .note {
        color: $grey;
        font-size: 13px;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .btn+.note {
        margin: 15px 0;
    }
    .remember  {
	    padding-top: 5px;
        label {
            font-size: 14px;
            color: $text-color-secondary;
        }

    }
    .lead {
        font-size: 14px;
        color: $grey;
        margin-top: 30px;
    }

}

/* ======= Reset Password Page ======= */
.resetpass-section {
    .form-box .title {
        margin-bottom: 30px;
    }
    .intro {
        font-size: 16px;
        margin-bottom: 30px;
    }
}

/* ======= Footer ======= */
.footer {
    background: $color-2;
    color: #fff;
    padding-top: 40px;
    font-size: 14px;
    position: relative;
    z-index: 20;
    p {
       @include opacity(0.5);
    }

    .btn-cta, a.btn-cta {
        padding: 8px 10px;
    }

    .footer-col {
        &.links {
            .svg-inline--fa {
                margin-right: 5px;

            }
            li {
                margin-bottom: 10px;
            }
        }
        &.connect {
            .social {
                margin-bottom: 15px;
                overflow: hidden;
                li {
                    margin-right: 15px;
                    a {
                        .svg-inline--fa {
                            color: lighten($grey, 10%);
                            font-size: 22px;
                        }
                        &:hover {
                            .svg-inline--fa {
                                color: $color;
                            }
                        }
                    }
                }
            }
            .btn-cta-primary {
                background: lighten($color-2, 30%);
                border-color: lighten($color-2, 30%);
                &:hover {
                    background: lighten($color-2, 20%);
                    border-color: lighten($color-2, 20%);
                }
            }
        }

        .title {
            color: #fff;
            font-weight: normal;
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 20px;
            @include opacity(0.9);
        }
        p {
            color: #fff;
        }
        a {
            color: lighten($color-2, 40%);
            &:hover {
                color: lighten($color-2, 45%);
            }
        }
        .signup-form {
            padding-left: 0;
            padding-right: 0;

            .form-control {
                @include opacity(0.9);
                font-size: 14px;
                position: relative;
                top:2px;
                &:focus {
                    @include opacity(1);
                }
            }
            .form-group {
                display:inline-block;

            }
            .btn{
                font-size: 14px;
            }

        }

    }

    .has-divider {
        border-top: 1px solid lighten($color-2, 3%);
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .download {
        .download-list {
            li {
                margin-bottom: 15px;
                .btn-ghost {
                    text-align: left;
                    @include opacity(0.6);
                    width: 230px;
                    font-size: 14px;
                    &:hover {
                        @include opacity(1);
                    }
                }
            }
        }
    }

    .contact {
        p {
            @include opacity(0.5);
            a {
                @include opacity(1);
            }
        }

        a {
	        color: #fff;
	        &:hover {
		        @include opacity(1);
		        color: #fff;
	        }
        }
        .svg-inline--fa {
            margin-right: 10px;
            font-size: 16px;
            &.fa-map-marker-alt {
                margin-top: 3px;
            }
        }
        .email {
            .svg-inline--fa {
                font-size: 14px;
            }
        }
    }


    .bottom-bar {
        background: darken($color-2, 5%);
        color: lighten($grey, 10%);
        font-size: 14px;
        padding: 10px 0;
        text-align: center;



        .copyright {
            line-height: 1.6;
            a {
	             color: lighten($grey, 10%);
            }
        }
    }

}

/* ======= Pagination ======= */
.pagination {
    margin-bottom: 60px;
    font-size: 14px;
    font-family: arial, sans-serif;
    margin-left: auto;
    margin-right: auto;
    li {
        a {
            @include border-radius(4px);
            border: none;
            margin-right: 5px;
            color: $color;
            &:hover {
                color: darken($color, 15%);
            }
        }
    }
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: $color;
    border:none;

}

.pagination>li>a:hover, .pagination>li>span:hover, .pagination>li>a:focus, .pagination>li>span:focus  {
    background-color: lighten($color, 30%);
    border:none;
}

.pagination>li:first-child>a, .pagination>li:first-child>span, .pagination>li:last-child>a, .pagination>li:last-child>span {
    @include border-radius(4px);
    background:none;
}

.page-item.active .page-link {
    background-color: $color;
    color: #fff;
}

.pagination>li>a:focus, .pagination>li>span:focus {
    color: $color;
}

/* ======= Search Form ======= */
.searchbox-icon,
.searchbox-submit{
    width:38px;
    height:38px;
    display:block;
    position:absolute;
    top:4px;
    font-size:16px;
    right:0;
    padding:0;
    margin:0;
    border:0;
    outline:0;
    line-height:2.4;
    text-align:center;
    cursor:pointer;
    color: lighten($color, 5%);
    background: #fff;
}

.searchbox-icon:hover {
    color: darken($color, 5%);
}

.searchbox-submit {
    font-size: 14px;
    font-family: arial, sans-serif;
}


.searchbox-container {
    position: absolute;
    right: 0;
    width: 100%;
    top: 15px;
    .searchbox {
        position: relative;
        display: inline-block;
        height: 42px;
        width: 100%;
        float: right;
        top: 2px;
        right:0;


        /* new */
        min-width: 38px;
        width: 0%;
        overflow: hidden;
        @include transition(width 0.3s);

        &.searchbox-open {
            width: 100%;
            z-index: 10;
            position: relative;
            .searchbox-icon,
            .searchbox-submit {
                background:$color;
                color: #fff;
                @include border-radiuses (4px, 4px, 0, 0);
            }
        }

        .searchbox-input {
            position: absolute;
            top: 4px;
            right: 0;
            border: none;
            outline: none;
            background: $smoky-white;
            border: 1px solid $divider;
            //width: 100%;
            height: 38px;
            margin: 0;
            padding-left: 20px;
            font-size: 13px;
            @include placeholder (lighten($grey, 5%));
        }

    }

}

/* ======= IE9 Fix ======= */

.ie9, .ie8 {

    .pricing .item .ribbon {
        top: 0px;
        right: 0px;
        height: 60px;
    }

    .pricing .item .ribbon .text {
        position: static;
        font-size: 13px;
        width: auto;
        padding: 5px;
    }

    .pricing .item .ribbon .text:before, .pricing .item .ribbon .text:after {
        display: none;
    }

}




